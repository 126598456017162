<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'ManagingInventory',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },

  methods: {
    // 获取供应商库存-库存管理数据列表
    myBidData () {
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        ...this.childData.searchData
      }
      request('/api/order/supplier/querySuppList', 'POST', obj).then((res) => {
        if (res.code === '200') {
          if (res.data.code === '200') {
            this.tableData = res.data.data.rows
            if (this.paginationData.total !== res.data.data.total) {
              this.paginationData.total = res.data.data.total
            }
          }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'MyBidRating' }, query: { objData: data.id } })
      } else {
      }
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
// @import '@/assets/css/noData.scss';
</style>
